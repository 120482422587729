import ViewComponent from '#components/view-component.js';
import Form from '#components/form.js';
import FormField from '#components/form-field.js';
import Message from '#components/message.js';

const TOOLS = ['HTML', 'CSS', 'JavaScript', 'React', 'Redux', 'Node'].map(tool => ({ label: tool, value: tool }));

function handleSelectChange(e) {
	const { target } = e;
	if (target.name === 'key') {
		const { form } = target;
		const { name, url_slug, description, url, archived_url, date_completed, tools } = this.props.projects.find(project => project.url_slug === target.value) ?? {};
		form.name.value = name;
		form.urlSlug.value = url_slug;
		form.description.value = description;
		form.url.value = url;
		form.archivedUrl.value = archived_url;
		form.dateCompleted.value = date_completed;
		tools.forEach(tool => {
			for (let i = 0; i < form.tools.options.length; ++i) {
				const option = form.tools.options[i];
				if (option.value === tool) {
					option.selected = true;
				}
			}
		});

		// TODO: include thumbnail and images
	}
}

export default new ViewComponent({
	defaultAttributes: {
		is: 'x-page-admin-project',
	},
	defaultProps: {
		activeProject: ''
	},
	connectedCallback: function () {
		this.addEventListener('change', handleSelectChange);
	},
	render: function () {
		const { projects } = this.props;

		return html`<main>
			<hgroup>
				<h1>Admin Projects</h1>
				<p>Create a project.</p>
			</hgroup>

			${Form(
				{ method: 'post', action: '/admin/projects?action=add', autocomplete: 'off', id: 'project', submitText: 'Create Project', resetText: 'Cancel' },
				FormField({ name: 'key', as: 'select', label: 'Project', options: [{ label: '', value: '' }].concat(projects.map(project => ({ label: project.name, value: project.url_slug }))) }),
				FormField({ name: 'name', type: 'text', label: 'Name', errorMessage: 'Please enter a name', required: true }),
				FormField({ name: 'urlSlug', type: 'text', label: 'URL Slug', errorMessage: 'Please enter a slug', required: true }),
				FormField({ name: 'description', as: 'textarea', label: 'Description', errorMessage: 'Please enter a description', required: true }),
				FormField({ name: 'url', type: 'text', label: 'URL', errorMessage: 'Please enter a URL', required: true }),
				FormField({ name: 'archivedUrl', type: 'text', label: 'Archived URL', errorMessage: 'Please enter an archived URL' }),
				FormField({ name: 'category', type: 'text', label: 'Category', errorMessage: 'Please select a category', required: true }),
				FormField({ name: 'dateCompleted', type: 'datetime-local', label: 'Date Completed', errorMessage: 'Please enter a completion date', required: true }),
				FormField({ name: 'tools', as: 'select', label: 'Tools', errorMessage: 'Please select some tools', required: true, options: TOOLS, multiple: true }),
				FormField({ name: 'thumbnail', type: 'file', label: 'Thumbnail', errorMessage: 'Please upload a thumbnail', accept: 'image/png, image/jpeg' }),
				FormField({ name: 'images', type: 'file', label: 'Images', errorMessage: 'Please upload some images', multiple: true, accept: 'image/png, image/jpeg' }),
			)}
		</main>`;
	}
});
