import ViewComponent from '#components/view-component.js';
import Form from '#components/form.js';
import Message from '#components/message.js';

const TOOLS = ['HTML', 'CSS', 'JavaScript', 'React', 'Redux', 'Node'].map(tool => ({ label: tool, value: tool }));

function handleSuccess(e) {
	const formData = e.detail;
	this.props.messages = this.props.messages.filter(({ key }) => key !== formData.get('key'));
}

function handleSelectAll(e) {
	const { target } = e;
	if (target.name === 'selectAll') {
		this.querySelectorAll('[name="deleteAndBlock"]').forEach(c => c.checked = target.checked);
	}
}

const Admin = new ViewComponent({
	defaultAttributes: {
		is: 'x-page-admin',
	},
	connectedCallback: function () {
		this.addEventListener('success', handleSuccess);
		this.addEventListener('change', handleSelectAll, true);
	},
	disconnectedCallback: function () {
		this.removeEventListener('success', handleSuccess);
	},
	render: function () {
		const { messages, error } = this.props;
		return html`
			<main>
				<hgroup>
					<h1>Admin</h1>
					<p>With great power comes great responsibility.</p>
				</hgroup>

				${error ? Message({ type: 'error', message: 'Your token may be expired.' }) : ''}


				<section>
					<h2>Messages</h2>

					${Form(
						{ method: 'post', action: '/admin?action=delete-message', id: 'messages', successMessage: 'Message deleted.', errorMessage: 'There was an error deleting the message.' },
						html`<button type="submit">Delete and Block Checked</button>`
					)}

					<table>
						<thead>
							<tr>
								<th><input type="checkbox" name="selectAll" /></th>
								<th>Name</th>
								<th>Phone</th>
								<th>Email</th>
								<th>Message</th>
								<th>IP</th>
								<th>Action</th>
							</tr>
						</thead>

						<tbody>
							${messages.map(({ key, name, phone, email, message, ip }) => html`
							<tr>
								<td><input type="checkbox" name="deleteAndBlock" value="${key}" form="messages" aria-label="Flag to delete and block IP" /></td>
								<td>${name}</td>
								<td>${phone}</td>
								<td>${email}</td>
								<td>${message}</td>
								<td>${ip}</td>
								<td><button type="submit" name="key" value="${key}" form="messages">Delete</button></td>
							</tr>
							`)}
						</tbody>
					</table>
				</section>
		`;
	}
});

export default Admin;
